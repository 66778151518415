export const API_ENDPOINTS = {
  "login":"login",
  "listOfConferences": "listOfConferences",
  "conferenceInfo":"conferenceInfo",
  "listOfAttendees":"listOfAttendees",
  "listOfProfiles":"listOfProfiles",
  "profileDetails":"profileDetails",
  "attendeesInfo":"attendeesInfo",
  "listOfObjectives":"listOfObjectives",
  "actions": "actions",
  "attendees":"attendees",
  "listOfFollowups":"listOfFollowups",
  "saveFollowup":"saveFollowup",
  "attendeeFilter":"attendeeFilter",
  "typeFilter":"typeFilter",
  "updatePeople":"updatePeople",
  "updateArcutisUser":"updateArcutisUser",
  "addMeetingInputs":"addMeetingInputs",
  "updateMeeting":"updateMeeting",
  "updateAttendees":"updateAttendees",
  "createGroupChat":"createGroupChat",
  "reports":"reports",
  "traceMail":"traceMail",
  "getTemplateAddMeetingsBulk":"getTemplateAddMeetingsBulk",
  "addMeetingsBulk":"addMeetingsBulk",
  "addConference":"addConference",
  "getStagingDetails":"getStagingDetails",
  "updateStagingDetails":"updateStagingDetails",
  "getConference":"getConference",
  "getEvents":"getEvents",
  "domainCheck":"domainCheck",
  "ssoLogin":"ssoLogin",
  "logout":"logout",
  "listOfSurveys":"listOfSurveys",
  "surveyTemplate":"surveyTemplate",
  "surveyKOLs":"surveyKOLs",
  "surveyConferences":"surveyConferences",
  "surveyMeetings":"surveyMeetings",
  "submitSurveyResponse":"submitSurveyResponse",
  "submittedResponses":"submittedResponses",
  "surveyFilters":"surveyFilters",
  "usersList":"usersList",
  "userOps":"userOps",
  "userData":"userData",
  "rolesList":"rolesList",
  "kolEdit":"kolEdit",
  "kolSections":"kolSections",
  "kolProfile":"kolProfile",
  "kols":"kols",
  "products":"products",
  "prescription":"prescription",
  "portfolios":"portfolios",
  "summary":"summary",
  "hcpGroupChat":"hcpGroupChat",
  "intTabs":'intTabs'
};

export const API_ENVIRONMENT = {
  "company-info": "local",
  // 'userLogin': 'dev',
};

//update this for each deployment
export const Version = '2.0.11';
