import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Progress,
  List,
  Tooltip,
  Button,
  Layout,
  Avatar,
  Space,
  Image,
  Dropdown,
  theme,
  Divider,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactEcharts from "echarts-for-react";
import { Content } from "antd/es/layout/layout";
import "./TLLScoreCard.scss";
import {
  UserOutlined
} from "@ant-design/icons";
import moreImage from "../../../../../Assets/images/3 dots.svg";
import TextArea from "antd/es/input/TextArea";
const { Title, Text } = Typography;

const TLLScoreCard = () => {

  // Echarts Bar Chart Configuration
  const getHorizontalBarChartOption = () => {
    return {
      title: {
        text: "Advocacy Score (Latest Quarter)",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "value",
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          fontSize: 12,
        }
      },
      yAxis: {
        type: "category",
        data: ["Q1 2024", "Q2 2024", "Q3 2024", "Q4 2024"],
        axisLabel: {
          interval: 0,
          fontSize: 12,
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      series: [
        {
          name: "Score",
          type: "bar",
          data: [4.0, 3.8, 5.0, 4.5],
          itemStyle: {
            color: "#215694", // Set the bar color here
          },
          barWidth: "50%",
          label: {
            show: true,
            position: "right", // Place the label on the right side for horizontal bars
            fontSize: 12,
          },
        },
      ],
    };
  };
  

  const getGaugeChartOption = () => {
    return {
      title: {
        text: "Latest Score",
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      series: [
        {
          name: "Gauge",
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 5,
          radius: "80%",
          splitNumber: 5,
          progress: {
            show: true,
            roundCap: true,
            width: 25,
            itemStyle: {
              color: "#59CD0D", // Set the progress color here
            },
          },
          pointer: {
            show: false, // Hide the pointer
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 25,
              color: [[1, "#D9D9D9"]],
            },
          },
          axisTick: {
            show: false, // Hide axis ticks
          },
          splitLine: {
            show: false, // Hide split lines
          },
          axisLabel: {
            show: false, // Hide axis labels
          },
          title: {
            show: false, // Hide the title inside the gauge
          },
          detail: {
            show: true,
            valueAnimation: true,
            fontSize: 16,
            color: "#777",
            formatter: "{value}/5",
            offsetCenter: [0, "40%"],
          },
          data: [
            {
              value: 4.5, // Latest score value
            },
          ],
        },
      ],
    };
  };
  
  const items = [
    {
      label: <Space>Edit</Space>,
      key: '0',
      evtype:'edit'
    }
  ];

  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };


  const dataJson = {
    tllCardContent: [
    "TLLs have consistently delivered valuable and up-to-date scientific content tailored to healthcare professionals.",
    "Highly positive interactions with KOLs, fostering deeper collaborations and trust.",
    "Conference Engagements: Winter Clinical HI, Maui Derm, AAD, SDPA"
  ]
}
 const [cardContents,setCardContents] = useState({...dataJson});
 const [edit,setEdit] = useState({})
 const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <>
      <div
        className="Tll-scorecard-root"
        data-test="Tll-scorecard-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">TLL Feedback</p>
                <Card
                  styles={{body:{padding:'20px'}}}
                  className="Tll-scorecard-Card"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "15px",
                  }}
                >
                  <div>
                    <Row gutter={[16, 16]}>
                      {/* Profile Section */}
                      <Col xs={24} md={8}>
                        <Card>
                          <Row>
                            <Col span={24}>
                            <Row>
                            <Col span={8}>
                            <Avatar
                                style={{
                                  verticalAlign: "middle",
								  margin: '8px 15px'
                                }}
                                size={120}
                              >
                                <UserOutlined style={{fontSize:"100px"}}/>
                              </Avatar>
                              </Col>
                              <Col span={16} className="userDetails">
                              <Title style={{ margin:0}} level={3}>Neel Bhatia</Title>
                              <p style={{margin:0,fontSize:"18px",fontWeight:"500"}}>1255349288</p>
                              <p style={{ margin:0,fontSize:'18px',fontWeight:"500"}}>Technical Director</p>
                              </Col>
                              </Row>
                            </Col>
                            <Col span={24} style={{ marginTop: "20px" }}>
                              <Card bordered={false}>
                                <Title level={5}>Engagement Plan</Title>
                                <ReactEcharts
                            option={getGaugeChartOption()}
                            style={{ height: "220px", marginTop: "20px" }}
                          />
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      {/* Advocacy Score and Bar Chart */}
                      <Col xs={24} md={16}>
                        <Card>
                          <Row justify="space-between" align="middle">
                            <Title level={5}>
                              Advocacy Score (Latest Quarter)
                            </Title>
                            <Tooltip title="Click to see the Advocacy Score Description">
                              <Button
                                icon={<InfoCircleOutlined />}
                                shape="circle"
                              />
                            </Tooltip>
                          </Row>
                          <ReactEcharts
                            option={getHorizontalBarChartOption()}
                            style={{ height: "380px", marginTop: "20px" }}
                          />
                        </Card>
                      </Col>

                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: "20px"}}>
                      <Col xs={24}>
                        <Card
                        title={"TLL"}
                        extra={
                          <div style={{display:'flex'}}>
                            <Dropdown
                              visible={dropdownVisible}
                              onVisibleChange={(flag) => setDropdownVisible(flag)}
                              placement="bottom"
                                dropdownRender={(menu) => (
                                  <div style={contentStyle}>
                                    <Space className='pointer'
                                     onClick={()=>{
										setEdit({...edit,tllCard:true});
										setDropdownVisible(false); 
									}}
                                      style={{
                                        padding: 8,
                                      }}
                                    >
                                      Edit 
                                    </Space>
                                  </div>
                                )}
                              trigger={['click']}
                            >
                              <p onClick={(e)=>{e.preventDefault()}}>
                                <Space className='pointer' style={{marginTop:'12px', fontSize:'20px'}}>
                                  <Image src={moreImage} alt="More" height={"15px"} style={{alignItems:"center", display:"flex"}} preview={false}/>
                                </Space>
                              </p>
                            </Dropdown>
                          </div>
                        } 
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        >
                           {edit?.tllCard ? (
        <div>
          <TextArea
            value={cardContents?.tllCardContent.join('\n')}
            onChange={(e) => setCardContents({...cardContents,tllCardContent:e.target.value.split('\n')})}
            rows={6}
            style={{ width: '100%' }}
          />
		   <Row gutter={[16, 16]} style={{ marginTop: "20px" ,marginRight:"5px" ,justifyContent:"flex-end"}}>
          <Button style={{marginRight:"10px"}} onClick={()=>{setEdit({...edit,tllCard:false})}}>Cancel</Button>
          <Button type="primary" onClick={()=>{
			console.log(cardContents?.tllCardContent,'cardContents?.tllCardContent')  
			setEdit({...edit,tllCard:false});
			}}>Save</Button>
		  </Row>
        </div>
      ) :
                          <List
                           dataSource={cardContents?.tllCardContent}
						   renderItem={(item) => (
							<List.Item>
							  <span style={{ marginRight: '8px', fontWeight: 'bold' ,fontSize:"15px"}}>•</span> {/* Bullet point */}
							  {item}
							</List.Item>
						  )}
                           />}
                        </Card>
                      </Col>

                      <Col xs={24}>
                        <Card
                        title={"Marketing"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        >
                          <List
                            dataSource={[
                              "Recent campaigns have shown strong engagement metrics, particularly in digital channels.",
                              "The messaging across various platforms is resonating well with the target audience.",
                              "The brand’s reputation has seen a significant boost, especially among younger healthcare professionals.",
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                      <Col xs={24}>
                        <Card
                        title={"Medical and Sales"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} 
                        >
                          <List
                            dataSource={[
                              "Strong relationships have been built with key accounts, leading to increased loyalty and repeat business.",
                              "Sales teams are well-versed in product details, enhancing credibility during discussions with physicians.",
                              "Sales strategies are well-aligned with overall objectives, ensuring consistent messaging and goals.",
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                     
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
                    <Col xs={24} md={12}>
                        <Card
                        title={"Podium Noble Statements"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} >
                          <List
                            dataSource={[
                              'Maui Derm 2/2024 - "There has been nothing new for SD for years... until now".',
                              "SDDS - Highlighted rapid itch relief, durable response and reiterated that it is a disease predominately of inflammation.",
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                      <Col xs={24} md={12}>
                        <Card
                        title={"Competitive Involvement"}
                        styles={{header:{backgroundColor:"#F3E8E8"}}} >
                          <List
                            dataSource={[
                              "Advisor - Multiple Companies.",
                              'Author - "Tapinarof cream 1% once daily for the treatment of plaque psoriasis: Patient-reported outcomes from the PSOARING 3 trial".',
                            ]}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default TLLScoreCard;
