import { Avatar, Card, Row, Col, Space, Flex, Typography, Timeline, Radio, Button, Table, Switch, Tag, Input, DatePicker, theme, Steps, message , Checkbox, Descriptions, Upload, Tooltip, Tabs, Modal,Empty} from "antd";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import { Select } from 'antd';
import './Engagement.scss'
import { ArrowRightOutlined,CloseOutlined, UserOutlined,WechatWorkOutlined, UploadOutlined,EditOutlined,GroupOutlined,EnvironmentOutlined,ClockCircleOutlined,CalendarOutlined, EditFilled, ClockCircleFilled,EnvironmentFilled, CalendarFilled } from '@ant-design/icons';
import { Option } from "antd/es/mentions";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { getDecryptSession } from "../../../Shared/Utils/CommonUtils";
import { useSharedState } from "../../../Shared/Services/WindowSizeService"
import dayjs from 'dayjs';
import classNames from 'classnames';
import DrawerComponent from "./Drawer/Drawer";
import FollowUp from "./FollowUp/FollowUp";
import GroupChatIcon from '../../../Assets/images/group_chat_icon.svg'
import TabPane from "antd/lib/tabs/TabPane";
import useWindowSize from "../../../Shared/Utils/windowResizeUtils";
import moment from "moment";



export default function Engagement({ ...props }) {
    const windowSize$ = useSharedState();

    const [formData, setFormData] = useState({
        action_on: '',
        action_assigned_to: '',
        action_status: '',
        id: '',
        type: 'save',
        composeMail:''
    });

    const { Title, Text } = Typography;
    const [addInputFieldData, setAddInputFieldData] = useState();
    const [loader, setLoader] = useState(true);
    const [addEditState, setAddEditState] = useState();
    const [inputs, setInputs] = useState();
    const [value, setValue] = useState(1);
    const [showActionSelection, setShowActionSelection] = useState(true);
    const navigate = useNavigate();
    const { meetingID } = useParams();
    // let { addNew } = useParams();
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState({});
    const [objectiveToggleValue, setObjectiveToggleValue] = useState(true);
    const [objectiveLists, setObjectiveList] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [drawerState, setDrawerState] = useState();
    const [mailComposeInputs, SetMailComposeInputs] = useState();
    const [currentAction, setCurrentAction] = useState();
    const [validateMailFlag, setValidateMailFlag] = useState();
    const [followUpList, setFollowUpList] = useState({
        headerContent: [],
        bodyContent: []
    });
    const [actionDetails, setActionsDetails] = useState({
        action_assigned_to: [],
        action_on: [],
        action_status: []
    });
    const [attendeeList, setAttendeeList] = useState({
        engagementAndNotes: [],
        tableData: {
            headerContent: [],
            bodyContent: []
        }
    });
    const { id } = useParams();
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    const [tabList, settabList] = useState(
        [
            {
                label: 'Objectives',
                key: 'objectives'
            },
            {
                label: 'Attendee',
                key: 'attendee'
            },
            {
                label: 'Follow Up',
                key: 'followUp'
            }]);

    const [organizerAttendees, setOrganizerAttendees] = useState([]);
    const [requiredAttendees, setRequiredAttendees] = useState([{
        "value": "all",
        "label": "Select All"
    }]);
    const [optionalAttendees, setOptionalAttendees] = useState([{
        "value": "all",
        "label": "Select All"
    }]);

    const [organizerKOLs, setOrganizerKOLs] = useState([]);
    const [requiredKOLs, setRequiredKOLs] = useState([{
        "value": "all",
        "label": "Select All"
    }]);
    const [optionalKOLs, setOptionalKOLs] = useState([{
        "value": "all",
        "label": "Select All"
    }]);

    const [orgIds, setOrgIds] = useState([]);
    const [reqIds, setReqIds] = useState([]);
    const [optIds, setOptIds] = useState([]);

    const [orgKOLIds, setOrgKOLIds] = useState([]);
    const [reqKOLIds, setReqKOLIds] = useState([]);
    const [optKOLIds, setOptKOLIds] = useState([]);

    const [meetingDetails, setMeetingDetails] = useState();
    const [showQuickEditModal, setShowQuickEditModal] = useState(false);
    const [quickEditUserDetails, setQuickEditUserDetails] = useState({});
    const [quickEditUserFormErrors, setQuickEditUserFormErrors] = useState({});
    const [quickEditUserId,setQuickEditUserId] = useState();
    const steps = [
        {
            title: 'First',
            content: 'First-content',
        },
        {
            title: 'Second',
            content: 'Second-content',
        }
    ];

    const defaultColumns = [
        {
            title: 'age',
            dataIndex: 'age',
        },
        {
            title: 'name',
            dataIndex: 'name',
            width: '30%',
            editable: true,
        }
    ];

    //to get the date from url
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const startdateParam = searchParams.get('startdate');
    const enddateParam = searchParams.get('enddate');
    const startDate = moment(decodeURIComponent(startdateParam), moment.ISO_8601, true);
    const endDate = moment(decodeURIComponent(enddateParam), moment.ISO_8601, true);
    // useEffect(()=>{
    //     console.log(mailComposeInputs);
    //     console.log(userDetails);
    // },[mailComposeInputs])

    // useEffect(()=>{
    //     console.log(validateMailFlag,formData?.composeMail);
    //     if(!validateMailFlag && formData?.composeMail===2){
    //         setFormData(prevFormData => ({
    //             ...prevFormData, // Spread the previous formData object
    //             "composeMail": 1    // Update the specified property
    //         }));
    //     }
    // },[validateMailFlag])

    const checkMailFlag = (event) => {
        // console.log(event)
        setValidateMailFlag(event);
        if(!event && formData?.composeMail===2){
            setFormData(prevFormData => ({
                ...prevFormData, // Spread the previous formData object
                "composeMail": 1    // Update the specified property
            }));
        }
    }


    useEffect(() => {
        const session = localStorage.getItem("arcutis-user");
        const userDetails = getDecryptSession(session);
        // console.log(userDetails);
        setUserDetails(userDetails)
        // console.log(meetingID)
        if (meetingID === 'addMeeting') {
            setAddEditState('add');
            setIsEditEnabled(true)
            fetchAddInputsList();
        } else {
            if(localStorage.getItem("editMeet")==="true"){
                // console.log(localStorage.getItem("editMeet"));
                editMeeting()
            }
            else{
                // console.log(localStorage.getItem("editMeet"));
                localStorage.setItem("editMeet",false);
                setIsEditEnabled(false)
                getSelectedMeeting();
                objectiveList();
                getActions();
                getAttendee();
                getListOfFollowups();
            }
        }
    }, []);

    useEffect(() => {
        if (addEditState === 'edit') {
            setIsEditEnabled(true)
            // fetchAddInputsList();
            // call default values for meeting
        }
    }, [addEditState])

    useEffect(() => {
        if (isEditEnabled) {
            fetchAddInputsList()
        }
        else {
            setAddInputFieldData();
            setInputs()
            setOrgIds()
            setReqIds()
            setOptIds()
            setOrganizerAttendees()
            setRequiredAttendees()
            setOptionalAttendees()
            setOrgKOLIds()
            setReqKOLIds()
            setOptKOLIds()
            setOrganizerKOLs()
            setRequiredKOLs()
            setOptionalKOLs()
        }
    }, [isEditEnabled])

    const removeAddOrg = (actualData, filteredData, current, va) => {
        // console.log(filteredData);
        let req = requiredAttendees?.length >= 1 ? requiredAttendees : addInputFieldData?.attendee;
        let opt = optionalAttendees?.length >= 1 ? optionalAttendees : addInputFieldData?.attendee;
        let arr2 = filteredData;
        if (addEditState === "edit") {
            let resReq = req.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = opt.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            setRequiredAttendees(resReq);
            setOptionalAttendees(resOpt);
        }
        else {
            // console.log(req);
            let resReq = req.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = opt.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            // console.log(resReq);
            setRequiredAttendees(resReq);
            setOptionalAttendees(resOpt);
        }
    }

    const removeAddReq = (actualData, filteredData, current) => {
        let org = organizerAttendees?.length >= 1 ? organizerAttendees : addInputFieldData?.attendee;
        let opt = optionalAttendees?.length >= 1 ? optionalAttendees : addInputFieldData?.attendee;
        let arr2 = filteredData;
        if (addEditState === "edit") {
            let resOrd = org.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = opt.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerAttendees(resOrd);
            setOptionalAttendees(resOpt);
        }
        else {            
            let resOrd = org.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = opt.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerAttendees(resOrd);
            setOptionalAttendees(resOpt);
        }
    }

    const removeAddOpt = (actualData, filteredData, current) => {
        let org = organizerAttendees?.length >= 1 ? organizerAttendees : addInputFieldData?.attendee;
        let req = requiredAttendees?.length >= 1 ? requiredAttendees : addInputFieldData?.attendee;
        let arr2 = filteredData;
        if (addEditState === "edit") {
            let resOrd = org.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resReq = req.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerAttendees(resOrd);
            setRequiredAttendees(resReq);
        }
        else {
            let resOrd = org.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            let resReq = req.filter(item => !arr2.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerAttendees(resOrd);
            setRequiredAttendees(resReq);
        }
    }

    const handleOrganiserChange = (va, lab, ind, i,type) => {
        // console.log(va);
        if(type==="single"){
            if(va){
                va=[va]
            }
            else{
                va=[]
            }
        }
        // console.log(orgIds)
        // setOrgIds(va);
        // 
        if (orgIds?.length >= va?.length) {
            // console.log(orgIds.length, va.length)
            for (i = 0; i < orgIds?.length; i++) {
                if (!va.includes(orgIds[i])) {
                    // console.log('diff',orgIds[i])
                    let temp = addInputFieldData?.attendee.filter(key => key.value === orgIds[i])
                    // console.log(temp[0]);
                    requiredAttendees.push(temp[0]);
                    optionalAttendees.push(temp[0]);
                }
            }
            setRequiredAttendees(requiredAttendees);
            setOptionalAttendees(optionalAttendees);
        }

        let filteredKeys = [];
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < organizerAttendees.length; i++) {
                    if (organizerAttendees[i].value !== "all") {
                        checkAllOption.push(organizerAttendees[i].value)
                        checkAllOptions.push(organizerAttendees[i])
                    }
                }
            }
            else {
                let temp = organizerAttendees.filter(key => (key.value === va[i]) && key.value !== "all");
                // console.log(temp);
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setOrgIds(checkAllOption);
            removeAddOrg(organizerAttendees, checkAllOptions, "organiser", va)
        }
        else {
            // console.log(va);
            setOrgIds(va);
            removeAddOrg(organizerAttendees, filteredKeys, "organiser", va)
        }
        // console.log(organizerAttendees.length,checkAllOption.length);
    }


    const handleRequiredChange = (va, lab, ind, i) => {
        // setReqIds(va)
        // console.log(va,lab)
        // console.log(ind,i);
        // console.log(reqIds);
        if (reqIds?.length > va?.length) {
            for (i = 0; i < reqIds?.length; i++) {
                if (!va.includes(reqIds[i])) {
                    let temp = addInputFieldData?.attendee.filter(key => key.value === reqIds[i])
                    organizerAttendees.push(temp[0]);
                    optionalAttendees.push(temp[0]);
                }
            }
            setOrganizerAttendees(organizerAttendees);
            setOptionalAttendees(optionalAttendees);
        }
        let filteredKeys = [];
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < requiredAttendees.length; i++) {
                    if (requiredAttendees[i].value !== "all") {
                        checkAllOption.push(requiredAttendees[i].value)
                        checkAllOptions.push(requiredAttendees[i])
                    }
                }
            }
            else {
                let temp = requiredAttendees.filter(key => (key.value === va[i]) && key.value !== "all");
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setReqIds(checkAllOption);
            removeAddReq(requiredAttendees, checkAllOptions, "required", va)
        }
        else {
            setReqIds(va);
            removeAddReq(requiredAttendees, filteredKeys, "required")
        }
        // removeExistingData(requiredAttendees,filteredKeys,"required")
    }


    const handleOptionalChange = (va, lab, ind, i) => {
        // setOptIds(va);
        // console.log(va,lab)
        // console.log(ind,i);
        let filteredKeys = [];
        if (optIds?.length > va?.length) {
            for (i = 0; i < optIds?.length; i++) {
                if (!va?.includes(optIds[i])) {
                    let temp = addInputFieldData?.attendee.filter(key => key.value === optIds[i])
                    organizerAttendees.push(temp[0]);
                    requiredAttendees.push(temp[0]);
                }
            }
            setOrganizerAttendees(organizerAttendees);
            setOptionalAttendees(optionalAttendees);
        }
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < optionalAttendees?.length; i++) {
                    if (optionalAttendees[i].value !== "all") {
                        checkAllOption.push(optionalAttendees[i].value)
                        checkAllOptions.push(optionalAttendees[i])
                    }
                }
            }
            else {
                let temp = optionalAttendees.filter(key => (key.value === va[i]) && key.value !== "all");
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setOptIds(checkAllOption);
            removeAddOpt(optionalAttendees, checkAllOptions, "optional", va)
        }
        else {
            setOptIds(va);
            removeAddOpt(optionalAttendees, filteredKeys, "optional")
        }
    }

    // test

    const removeAddKOLOrg = (actualData, filteredData, current, va) => {
        let reqKOL = requiredKOLs?.length >= 1 ? requiredKOLs : addInputFieldData?.kol_profile;
        let optKOL = optionalKOLs?.length >= 1 ? optionalKOLs : addInputFieldData?.kol_profile;
        let arr2KOL = filteredData;
        if (addEditState === "edit") {
            let resReq = reqKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = optKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setRequiredKOLs(resReq);
            setOptionalKOLs(resOpt);
        }
        else {

            let resReq = reqKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = optKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setRequiredKOLs(resReq);
            setOptionalKOLs(resOpt);
        }
    }

    const removeAddKOLReq = (actualData, filteredData, current) => {
        let orgKOL = organizerKOLs?.length >= 1 ? organizerKOLs : addInputFieldData?.kol_profile;
        let optKOL = optionalKOLs?.length >= 1 ? optionalKOLs : addInputFieldData?.kol_profile;
        let arr2KOL = filteredData;
        if (addEditState === "edit") {
            let resOrd = orgKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = optKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerKOLs(resOrd);
            setOptionalKOLs(resOpt);
        }
        else {
            let resOrd = orgKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resOpt = optKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerKOLs(resOrd);
            setOptionalKOLs(resOpt);
        }
    }

    const removeAddKOLOpt = (actualData, filteredData, current) => {
        let orgKOL = organizerKOLs?.length >= 1 ? organizerKOLs : addInputFieldData?.kol_profile;
        let reqKOL = requiredKOLs?.length >= 1 ? requiredKOLs : addInputFieldData?.kol_profile;
        let arr2KOL = filteredData;
        if (addEditState === "edit") {
            let resOrd = orgKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resReq = reqKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerKOLs(resOrd);
            setRequiredKOLs(resReq);
        }
        else {
            let resOrd = orgKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            let resReq = reqKOL.filter(item => !arr2KOL.some(obj => obj.value === item.value && obj.label === item.label));
            setOrganizerKOLs(resOrd);
            setRequiredKOLs(resReq);
        }
    }

    const handleOrganiserKOLChange = (va, lab, ind, i,type) => {
        // console.log(va);
        // setOrgIds(va);
        if(type==="single"){
            if(va){
                va=[va]
            }
            else{
                va=[]
            }
        }
        if (orgKOLIds?.length > va?.length) {
            // console.log(orgIds.length, va.length)
            for (i = 0; i < orgKOLIds.length; i++) {
                if (!va.includes(orgKOLIds[i])) {
                    // console.log('diff',orgIds[i])
                    let temp = addInputFieldData?.kol_profile.filter(key => key.value === orgKOLIds[i])
                    // console.log(temp[0]);
                    requiredKOLs.push(temp[0]);
                    optionalKOLs.push(temp[0]);
                }
            }
            setRequiredKOLs(requiredKOLs);
            setOptionalKOLs(optionalKOLs);
        }

        let filteredKeys = [];
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < organizerKOLs?.length; i++) {
                    if (organizerKOLs[i].value !== "all") {
                        checkAllOption.push(organizerKOLs[i].value)
                        checkAllOptions.push(organizerKOLs[i])
                    }
                }
            }
            else {
                let temp = organizerKOLs.filter(key => (key.value === va[i]) && key.value !== "all");
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setOrgKOLIds(checkAllOption);
            removeAddKOLOrg(organizerKOLs, checkAllOptions, "organiser", va)
        }
        else {
            setOrgKOLIds(va);
            removeAddKOLOrg(organizerKOLs, filteredKeys, "organiser", va)
        }
        // console.log(organizerAttendees.length,checkAllOption.length);
    }


    const handleRequiredKOLChange = (va, lab, ind, i) => {
        // setReqIds(va)
        // console.log(va,lab)
        // console.log(ind,i);
        if (reqKOLIds?.length > va?.length) {
            for (i = 0; i < reqKOLIds?.length; i++) {
                if (!va.includes(reqKOLIds[i])) {
                    let temp = addInputFieldData?.kol_profile.filter(key => key.value === reqKOLIds[i])
                    organizerKOLs.push(temp[0]);
                    optionalKOLs.push(temp[0]);
                }
            }
            setOrganizerKOLs(organizerKOLs);
            setOptionalKOLs(optionalKOLs);
        }
        let filteredKeys = [];
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < requiredKOLs?.length; i++) {
                    if (requiredKOLs[i].value !== "all") {
                        checkAllOption.push(requiredKOLs[i].value)
                        checkAllOptions.push(requiredKOLs[i])
                    }
                }
            }
            else {
                let temp = requiredKOLs.filter(key => (key.value === va[i]) && key.value !== "all");
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setReqKOLIds(checkAllOption);
            removeAddKOLReq(requiredKOLs, checkAllOptions, "required", va)
        }
        else {
            setReqKOLIds(va);
            removeAddKOLReq(requiredKOLs, filteredKeys, "required")
        }
        // removeExistingData(requiredAttendees,filteredKeys,"required")
    }


    const handleOptionalKOLChange = (va, lab, ind, i) => {
        // optKOLIds(va);
        // console.log(va,lab)
        // console.log(ind,i);
        let filteredKeys = [];
        if (optKOLIds?.length > va?.length) {
            for (i = 0; i < optKOLIds?.length; i++) {
                if (!va.includes(optKOLIds[i])) {
                    let temp = addInputFieldData?.kol_profile.filter(key => key.value === optKOLIds[i])
                    organizerKOLs.push(temp[0]);
                    requiredKOLs.push(temp[0]);
                }
            }
            setOrganizerKOLs(organizerKOLs);
            setOptionalKOLs(requiredKOLs);
        }
        let checkAllBoolean = false;
        let checkAllOption = [];
        let checkAllOptions = [];
        for (i = 0; i < va?.length; i++) {
            if (va[i] === "all") {
                checkAllBoolean = true;
                // console.log(va[i]);
                for (i = 0; i < optionalKOLs?.length; i++) {
                    if (optionalKOLs[i].value !== "all") {
                        checkAllOption.push(optionalKOLs[i].value)
                        checkAllOptions.push(optionalKOLs[i])
                    }
                }
            }
            else {
                let temp = optionalKOLs.filter(key => (key.value === va[i]) && key.value !== "all");
                filteredKeys.push(temp[0]);
            }
        }
        if (checkAllBoolean) {
            setOptKOLIds(checkAllOption);
            removeAddKOLOpt(optionalKOLs, checkAllOptions, "optional", va)
        }
        else {
            setOptKOLIds(va);
            removeAddKOLOpt(optionalKOLs, filteredKeys, "optional")
        }
    }

    // test

    // const columns = [
    //     {
    //         title: 'Attendee',
    //         dataIndex: 'label',
    //         key: 'label',
    //         width:"30%",
    //       },
    //     {
    //       title: 'Meetings Info',
    //       dataIndex: 'address_data',
    //       key: 'address_data',
    //       width:"70%",
    //       render(text,index,i) {
    //         return {
    //           props: {
    //             style: {cursor: "pointer",width:"100%"}
    //           },
    //           children: 
    //                 <Select
    //                     style={{
    //                         width: "100%",
    //                     }}
    //                     onChange={(value, label) => { handleTypeOnChange(value, label, index,i) }}
    //                     options={
    //                         [
    //                             {
    //                                 value: 'required',
    //                                 label: 'Required',
    //                             },
    //                             {
    //                                 value: 'optional',
    //                                 label: 'Optional',
    //                             },
    //                             {
    //                                 value: 'organizer',
    //                                 label: 'Organizer',
    //                             },
    //                         ]
    //                     }
    //                 />
    //         };
    //       }
    //     }
    //   ];

    // const [dataSource, setDataSource] = useState([
    //     {
    //         value:5,
    //         label: '32',
    //         address_data: '',
    //     },
    //     {
    //         value:6,
    //         label: '32',
    //         address_data: '',
    //     },
    //   ]);

    const filterOption = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const filter = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const filterOpt = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const filterKOLOption = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const filterKOL = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const filterKOLOpt = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const columns = [
        {
            title: 'Meeting Invitee Type',
            dataIndex: 'meeting_invitee',
            key: 'meeting_invitee',
            width: "20%",
        },
        {
            title: 'Arcutis Attendees',
            dataIndex: 'attendees',
            key: 'attendees',
            width: "40%",
            render(text, index, i) {
                return {
                    props: {
                        style: { cursor: "pointer" }
                    },
                    children:
                        <>
                            {text.type === "organiser" &&
                                <Select
                                    allowClear
                                    disabled={organizerAttendees?.length === 1 ? true : false}
                                    showSelectAll={true}
                                    // mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    // maxCount={1}
                                    value={orgIds}
                                    showSearch
                                    filterOption={filterOption}
                                    onChange={(value, keyLabels) => { handleOrganiserChange(value, keyLabels, index, i,"single") }}
                                    options={organizerAttendees}
                                >
                                    {/* <Option key="all" value="all">SELECT ALL</Option> */}
                                    {/* {childrenData()} */}
                                </Select>
                            }
                            {text.type === "required" &&
                                <Select
                                    allowClear
                                    disabled={requiredAttendees?.length === 1 ? true : false}
                                    showSelectAll={true}
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={reqIds}
                                    showSearch
                                    filterOption={filter}
                                    onChange={(value, label) => { handleRequiredChange(value, label, index, i) }}
                                    options={requiredAttendees}
                                >
                                    {/* <Option key="all" value="all">SELECT ALL</Option> */}
                                    {/* {childrenData()} */}
                                </Select>
                            }
                            {text.type === "optional" &&
                                <Select
                                    allowClear
                                    disabled={optionalAttendees?.length === 1 ? true : false}
                                    showSelectAll={true}
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={optIds}
                                    showSearch
                                    filterOption={filterOpt}
                                    onChange={(value, label) => { handleOptionalChange(value, label, index, i) }}
                                    options={optionalAttendees}
                                >
                                    {/* <Option key="all" value="all">SELECT ALL</Option> */}
                                    {/* {childrenData()} */}
                                </Select>
                            }
                        </>
                };
            }
        },
        {
            title: 'KOL Attendees',
            dataIndex: 'kols',
            key: 'kols',
            width: "40%",
            render(text, index, i) {
                return {
                    props: {
                        style: { cursor: "pointer"}
                    },
                    children:
                        <>
                            {text.type === "organiser" &&
                                <div >
                                    <Descriptions.Item>
                                        <Space style={{opacity:'50%'}}>
                                            No Organiser in KOL attendees
                                        </Space>
                                    </Descriptions.Item>
                                </div>
                                // <Select
                                //     allowClear
                                //     disabled={organizerKOLs?.length === 1 ? true : false}
                                //     showSelectAll={true}
                                //     // mode="multiple"
                                //     style={{
                                //         width: "100%",
                                //     }}
                                //     value={orgKOLIds}
                                //     showSearch
                                //     filterOption={filterKOLOption}
                                //     onChange={(value, keyLabels) => { handleOrganiserKOLChange(value, keyLabels, index, i,"single") }}
                                //     options={organizerKOLs}
                                // >
                                // </Select>
                                
                            }
                            {text.type === "required" &&
                                <Select
                                    allowClear
                                    disabled={requiredKOLs?.length === 1 ? true : false}
                                    showSelectAll={true}
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={reqKOLIds}
                                    showSearch
                                    filterOption={filterKOL}
                                    onChange={(value, label) => { handleRequiredKOLChange(value, label, index, i) }}
                                    options={requiredKOLs}
                                    tagRender={tagRender}
                                >
                                    {/* <Option key="all" value="all">SELECT ALL</Option> */}
                                    {/* {childrenData()} */}
                                </Select>
                            }
                            {text.type === "optional" &&
                                <Select
                                    allowClear
                                    disabled={optionalKOLs?.length === 1 ? true : false}
                                    showSelectAll={true}
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    value={optKOLIds}
                                    showSearch
                                    filterOption={filterKOLOpt}
                                    onChange={(value, label) => { handleOptionalKOLChange(value, label, index, i) }}
                                    options={optionalKOLs}
                                >
                                    {/* <Option key="all" value="all">SELECT ALL</Option> */}
                                    {/* {childrenData()} */}
                                </Select>
                            }
                        </>
                };
            }
        }
    ];

    const [dataSource, setDataSource] = useState([
        {
            meeting_invitee: "Organiser",
            attendees: {
                type: "organiser"
            },
            kols: {
                type: "organiser"
            }
        },
        {
            meeting_invitee: "Required",
            attendees: {
                type: "required"
            },
            kols: {
                type: "required"
            }
        },
        {
            meeting_invitee: "Optional",
            attendees: {
                type: "optional"
            },
            kols: {
                type: "optional"
            }
        }
    ]);

    const { token } = theme.useToken();

    const validateInputs = (meetingAttendessData) => {
        let flag = false;
        if (inputs) {
            // console.log(inputs);
            let count = 0;
            for (const [key, value] of Object.entries(inputs)) {
                if (typeof (value) === "object" && value && key !=="medical_objectives" && key!=="commercial_objectives"&& key !=="meeting_attachments" ) {
                    count = count + 1;
                    // console.log(value?.length);
                    //  console.log(`Key: ${key}, Value: ${value}`);
                }
                else if ((typeof (value) === "string" || typeof (value) === "number") && value && key !=="medical_objectives" && key!=="commercial_objectives" && key !=="meeting_notes" && key !=="meeting_attachments") {
                    count = count + 1;
                    // console.log(value);
                    //  console.log(`Key: ${key}, Value: ${value}`);
                }
                // console.log(`Key: ${key}, Value: ${value}`);
            }
            let temp = orgIds?.length ? true : false;
            // console.log(inputs);
            // console.log(count, temp);
            if (count === 7) {
                flag = true;
            }
            else {
                alertService.error('Please fill all required fields')
            }
        }
        else {
            alertService.error('Please fill all required fields')
        }
        return flag;
    }

    const SaveData = () => {
        let meetingAttendessData =
        {
            attendee_list: {
                organizer: orgIds,
                required: reqIds,
                optional: optIds
            },
            kol_profiles: {
                // organizer: orgKOLIds,
                required: reqKOLIds,
                optional: optKOLIds
            }
        }
        if (validateInputs(meetingAttendessData)) {
            updateMeetingServiceCall(inputs, meetingAttendessData)
        }
    };

    const contentStyle = {
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };


    const [selectedMenu, setMenu] = useState('objectives');

    const handleDropdownChange = (value, key,label) => {
        // console.log(key, value);
        setFormData(prevFormData => ({
            ...prevFormData, // Spread the previous formData object
            [key]: value    // Update the specified property
        }));
        // console.log(label?.children);
        if(key==="action_on" && label?.children){
            setCurrentAction(label?.children);
        }
        if(key==="composeMail" && value===2){
            setDrawerState("composeMail");
        }
        else{
            setDrawerState("");
        }
    };

    const onChange = (e) => {
        setValue(e.target.value);
        setShowActionSelection(!showActionSelection);
    };

    const getSelectedMeeting = () => {
        setSelectedMeeting({
            meetingTitle: JSON.parse(localStorage.getItem("meetingTitle")),
            meeting_host: JSON.parse(localStorage.getItem("meeting_host")),
        })
    }

    const objectiveList = () => {
        let payload = {
            meetingId: parseInt(meetingID),
            type: objectiveToggleValue === false ? 'Commercial Objective' : 'Medical Objective'
        }
        ServiceUtils.postRequest('listOfObjectives', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setObjectiveList(response.data.data?.objectives)
                    setMeetingDetails(response.data.data?.meeting_details)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching Objective.')
            }
        );
    }

    const getListOfFollowups = () => {
        let payload = {
            meetingId: parseInt(meetingID),
        }
        ServiceUtils.postRequest('listOfFollowups', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // response.data.data.headerContent.push({
                    //     title: 'Actions',
                    //     dataIndex: "actions",
                    //     key: 'actions',
                    //     render: (_, record) => (
                    //         <Space size="middle">
                    //             <a onClick={() => onActionSelect(record, 'edit')}>Edit </a>
                    //             <a onClick={() => onActionSelect(record, 'delete')}>Delete</a>
                    //             <a onClick={() => onSelectUserChat(record, 'chat')}>Trace Mail</a>
                    //         </Space>
                    //     ),
                    // })
                    // console.log(response.data.data);
                    setFollowUpList(response.data.data)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching data.')
            }
        );
    }

    const onActionSelect = (record, type) => {
        setFormData({
            action_on: record.action_on_id,
            action_assigned_to: record.action_assigned_to_id,
            action_status: record.action_status_id,
            id: record.id,
            type: type
        })
        // console.log('type', type)
        if (type === 'delete') {
            // let payload = {
            //     meetingId: parseInt(meetingID),
            //     "action_on": record.action_on_id,
            //     "action_assigned_to": record.action_assigned_to_id,
            //     "action_status": record.action_status_id,
            //     id: record.id,
            //     type: 'delete'
            // }
            let payload = {
                data:JSON.stringify({
                    meetingId: parseInt(meetingID),
                    "action_on": record.action_on_id,
                    "action_assigned_to": record.action_assigned_to_id,
                    "action_status": record.action_status_id,
                    id: record.id,
                    type: 'delete'
                })
            }
            // console.log(payload)
            ServiceUtils.postRequest('saveFollowup', payload).then(
                (response) => {
                    if (response.status === 200 && response?.data.status) {
                        getListOfFollowups();
                        setFormData({
                            action_on: '',
                            action_assigned_to: '',
                            action_status: '',
                            id: '',
                            type: 'save',
                            composeMail:''
                        });

                        SetMailComposeInputs()
                        // test 10/5
                    }
                    else if(response.status === 401 ){
                        navigate('/logout');
                    }
                },
                (error) => {
                    alertService.error('Error while fetching data.')
                }
            );
        }
    }

    const onSelectUserChat = (record, type) => {
        // if(record?.mail){
        //     window.open("https://teams.microsoft.com/l/chat/0/0?users="+ record?.mail);
        // }
        setFormData({
            action_on: record.action_on_id,
            action_assigned_to: record.action_assigned_to_id,
            action_status: record.action_status_id,
            id: record.id,
            type: type
        })
        setDrawerState("traceMail");
    }

    const getAttendee = () => {
        let payload = {
            meetingId: parseInt(meetingID),
        }
        ServiceUtils.postRequest('attendees', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // console.log(response.data)
                    response.data.tableData.headerContent.map(el => {
                        if (el.key === 'name') {
                            el.render = (_, record) => (
                                <Space size="middle">
                                    <a onClick={() => onProfileSelect(record)}>{record.name}</a>
                                </Space>
                            )
                        } else if (el.key === 'invitation_type') {
                            el.render = (_, tags) => (
                                <Tag color={tags.backgroundColor} style={{padding:'3px 16px',minWidth: '5rem'}} key={tags}>
                                    <span style={{ color: 'black', fontWeight: '500' }}>{tags.invitation_type}</span>
                                </Tag>
                            )
                        } else if (el.key === 'chat') {
                            el.render = (_, tags) => (
                                <Space size="middle">
                                    {tags?.chat?.length ?
                                        <a onClick={() => {window.open(tags?.chat)}}>
                                            <WechatWorkOutlined />
                                            {"   "}
                                            Chat
                                            {" "}
                                        </a>
                                    :
                                        <> </>
                                    }
                                </Space>
                            )
                        }
                    })
                    setAttendeeList(response.data)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching data.')
            }
        );
    }

    const getActions = () => {
        let payload = {
            meetingId: parseInt(meetingID),
        }
        ServiceUtils.postRequest('actions', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    setActionsDetails(response.data.data)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching Objective.')
            }
        );
    }

    const onProfileSelect = (data) => {
        if (data.route !== '') {
            navigate(data.route)
        } else {
            alertService.error('There is no details of the user')
        }
    }

    const handleSubmit = (payload) => {
        if (formData.action_on == '' || formData.action_assigned_to == '' || formData.action_status == '' || formData.composeMail == '') {
            alertService.error('Fill all the required fields')
            return;
        }
        const session = localStorage.getItem("arcutis-user");
        const userDetails = getDecryptSession(session);
        // for (var va of mailComposeInputs?.attachments?.values()) {
        //     console.log(va); 
        // }
        // let payload = {
        //     data:JSON.stringify( {
        //         meetingId: parseInt(meetingID),
        //         "action_on": formData.action_on,
        //         "action_assigned_to": formData.action_assigned_to,
        //         "action_status": formData.action_status,
        //         "id": formData.id,
        //         "created_by": formData.type === 'save' ? userDetails.user_id : '',
        //         "updated_by": userDetails.user_id,
        //         "mail_type":formData.composeMail,
        //         "user_id":userDetails?.user_id,
        //         "user_email":userDetails?.user_email,
        //         type: formData.type,
        //         "subject": mailComposeInputs?.subject,
        //         "cc":mailComposeInputs?.cc,
        //         "bcc":mailComposeInputs?.bcc,
        //         "to":mailComposeInputs?.to,
        //         "body":mailComposeInputs?.body,
        //     }),
        //     "attachments":mailComposeInputs?.attachments,
        // }
        ServiceUtils.postRequest('saveFollowup', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    getListOfFollowups();
                    setFormData({
                        action_on: '',
                        action_assigned_to: '',
                        action_status: '',
                        id: '',
                        type: 'save',
                        composeMail:''
                    });
                    SetMailComposeInputs()
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching Objective.')
            }
        );
    };

    const fetchAddInputsList = () => {
        let payload = {
            meetingId: Number(meetingID) ? Number(meetingID) : null
        }
        ServiceUtils.postRequest('addMeetingInputs', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    let responseData = JSON.parse(JSON.stringify(response.data?.data));
                    // console.log(responseData?.inputFields);
                    setAddInputFieldData(responseData?.inputFields)


                    if (addEditState === "edit") {
                        // getDefaultValues()
                        setTimeout(() => {
                            setInputs(responseData?.defaultValues?.meeting_details)
                          }, 500);
                        setOrgIds(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.organizer)
                        setReqIds(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.required)
                        setOptIds(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.optional)
                        let myArray = JSON.parse(JSON.stringify(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.org_obj));
                        myArray.shift();
                        setOrganizerAttendees(myArray);
                        // setOrganizerAttendees(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.org_obj)
                        setRequiredAttendees(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.req_obj)
                        setOptionalAttendees(responseData?.defaultValues?.meeting_attendee_details?.attendee_list?.opt_obj)
                        setOrgKOLIds(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.organizer)
                        setReqKOLIds(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.required)
                        setOptKOLIds(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.optional)
                        setOrganizerKOLs(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.org_obj)
                        setRequiredKOLs(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.req_obj)
                        setOptionalKOLs(responseData?.defaultValues?.meeting_attendee_details?.kol_profiles?.opt_obj)
                    }
                    else {
                        let myArray = JSON.parse(JSON.stringify(responseData?.inputFields?.attendee));
                        myArray.shift();
                        setOrganizerAttendees(myArray)
                        if(startDate.isValid()&&endDate.isValid()){
                            setInputs({start_date:startDate,end_date:endDate})
                        }else if(startDate.isValid()){
                            setInputs({start_date:startDate,end_date:startDate})
                        }else{
                            setInputs({start_date:new Date(),end_date:new Date()});
                        }
                        setRequiredAttendees(responseData?.inputFields?.attendee)
                        setOptionalAttendees(responseData?.inputFields?.attendee)
                        setOrganizerKOLs(responseData?.inputFields?.kol_profile)
                        setRequiredKOLs(responseData?.inputFields?.kol_profile)
                        setOptionalKOLs(responseData?.inputFields?.kol_profile)
                    }
                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                } 
                else {
                    alertService.error(response.data.message)
                }
            },
            (error) => {
                alertService.error('Error while fetching data')
                console.log('userLogin Error', error)
            }
        );
    };

    const updateAttendeesServiceCall = (meetingAttendees, meeting_id_g,updated_data) => {
        let payload = {
            type: meetingID !== 'addMeeting' ? "edit" : "add",
            meeting_id: meeting_id_g ? meeting_id_g : null,
            meeting_details: meetingAttendees,
            arcutis_attendees_mail: inputs?.arcutis_attendees_mail ? inputs?.arcutis_attendees_mail : false,
            kol_attendees_mail:inputs?.kol_attendees_mail ? inputs?.kol_attendees_mail : false,
            user_id: userDetails?.user_id,
            user_email:userDetails?.user_email,
            updated_data:updated_data
        }
        ServiceUtils.postRequest('updateAttendees', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // alertService.success(response.data.message)
                    setIsEditEnabled(false);
                    
                    localStorage.setItem("editMeet", false);
                    localStorage.setItem('history', "/home/meetingDetails/" + id);
                    // navigate("/home/meetingDetails/" + id + "/engagement/" + meeting_id_g)
                    navigate("/home/meetingDetails/" + id)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message)
                    if (id && meeting_id_g) {
                        setIsEditEnabled(false);
                        // navigate("/home/meetingDetails/" + id + "/engagement/" + meeting_id_g)
                        navigate("/home/meetingDetails/" + id)
                        localStorage.setItem('history', "/home/meetingDetails/" + id);
                    } else {
                        navigate("/home")
                        localStorage.removeItem("history")
                    }
                }
            },
            (error) => {
                alertService.error('Error while fetching data')
                console.log(error)
            }
        );
    }

    const updateMeetingServiceCall = (meetingInputs, meetingAttendees) => {
        let payload =
        {
            type: meetingID !== 'addMeeting' ? "edit" : "add",
            meeting_id: Number(meetingID) ? Number(meetingID) : null,
            meeting_details: meetingInputs,
            user_id: userDetails?.user_id,
            user_email:userDetails?.user_email,
            time_zone:Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        ServiceUtils.postRequest('updateMeeting', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // console.log(response?.data.data)
                    updateAttendeesServiceCall(meetingAttendees, response?.data.data?.meeting_id,response?.data.data)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    // if (id && response?.data.data?.meeting_id) {
                    //     setIsEditEnabled(false);
                    //     navigate("/home/meetingDetails/" + id + "/engagement/" + response?.data.data?.meeting_id)
                    // } else {
                    //     navigate("/home")
                    //     localStorage.removeItem("history")
                    // }
                    alertService.error(response?.data?.message)
                }
            },
            (error) => {
                alertService.error('Error while fetching data')
                console.log(error)
            }
        );
    }

    const getDefaultValues = () => {
        let payload = {}
        ServiceUtils.getRequest('addMeetingInputs').then(
            (response) => {
                if (response.status === 200 && response?.data.status) {

                } 
                else if(response.status === 401 ){
                    navigate('/logout');
                }
                else {
                    alertService.error(response.data.message)
                }
            },
            (error) => {
                alertService.error('Error while fetching data')
                console.log('userLogin Error', error)
            }
        );
        setLoader(false);
        setInputs(
            {
                "medical_objectives": [
                    4,
                    3,
                    7
                ],
                "meeting_description": "temp",
                "start_date": "2020-06-09 12:40:14",
                "end_date": "2022-03-12 00:00:05",
                "meeting_type": [
                    2,
                    3
                ],
                "attendee_list": [
                    4,
                    8
                ],
                "kol_profiles": [
                    4,
                    8,
                    6
                ],
                "commercial_objectives": [
                    13
                ]
            }
        )
    };

    const handleCalender = (event, val, key) => {
        const name = key;
        const value = val;
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const handleSelectOnChange = (val, label, key) => {
        if (key === "attendee_list") {
            for (let i = 0; i < label.length; i++) {
                // console.log(label[i]);
                label[i]["address_data"] = ""
            }
        }
        // console.log(label);
        // console.log("val-",val,"label-", label, "key-",key);
        const name = key;
        const value = val;
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const multiAttchOnChange = (event) => {
        // console.log(event);
        // console.log(event);
        const name = "meeting_attachments";
        const value = event?.fileList;
        setInputs((values) => ({ ...values, [name]: value }));
    }

    const beforeUpload = (fil, fileList) => {
        // Access file content here and do something with it
        // console.log(fil)
        // console.log(fileList);
        let imageInputJson = [];
        // const fileListt = fileList;
        if (fileList.length > 0) {
          // imageInputJson.imageUploads = [];
          for (let i = 0; i < fileList.length; i++) {
            let file = fileList[i];
            for (const fileName of Object.values(fileList)) {
              // console.log(fileName,Object.values(fileList));
              const reader = new FileReader();
              let fileType = fileList[i].type;
              reader.onload = (frEvent) => {
                // console.log('test')
                const imgContent = frEvent.target['result'];
                if (imgContent.length > 0 ) {
                  let imgData = {};
                  imgData['file_name'] = fileList[i].name;
                  imgData['data'] = imgContent.split(',')[1];
                  imageInputJson.push(imgData); 
                  if(imageInputJson[0].data){
                    fileList[i]["data"]=imageInputJson[0]?.data;
                  }             
                  // console.log(imageInputJson[0].data);
                }
              };
              file= fileName;
              reader.readAsDataURL(file);
              // console.log(reader.readAsDataURL(file))
            }
          }
        }
        // console.log(fileList);
    
        // Prevent upload
        // return false
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 500);
    };

    const handleCheckboxChange = (event) => {
        const name = event.target.name;
        const value = event?.target.checked;
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const editMeeting = () => {
        setAddEditState('edit');
        if (meetingID) {
            setIsEditEnabled(true);
        }
    }

    const openGroupChat = (url) => {
        // window.open("https://teams.microsoft.com/l/chat/"+chatId+"/conversations");
        window.open(url);
    }

    const openGroupChatSer = () => {
        let payload = {
            meetingId: parseInt(meetingID),
        }
        ServiceUtils.postRequest('createGroupChat', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    // console.log(response?.data.data.chatId);
                    openGroupChat(response?.data.data.web_url)
                    // setFollowUpList(response.data.data)
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching data.')
            }
        );
    }

    const cancelAddEdit = () => {
        // setIsEditEnabled(false);
        if (id && meetingID) {
            // console.log(id);
            setIsEditEnabled(false);
            meetingID === 'addMeeting' ? navigate(-1) : navigate("/home/meetingDetails/" + id + "/engagement/" + meetingID);
        } else {
            navigate("/home")
            localStorage.removeItem("history")
        }
    }

    const onSave = () => {
        console.log(inputs);
    }

    const onTabSelect = (tabs) => {
        setMenu(tabs);
    };

    const setBg = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return "#" + randomColor;
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const saveQuickEditUserDetails=()=>{
        const error = {};

        if (!quickEditUserDetails.email) {
          error.email = 'Email is required!';
        } else if (!validateEmail(quickEditUserDetails.email)) {
          error.email = 'Please enter a valid email address!';
        }
    
        setQuickEditUserFormErrors(error);
    
        if (Object.keys(error).length === 0) {
          console.log('Form submitted with values:', quickEditUserDetails);
          let tempPayload = {
            ...quickEditUserDetails,
            userId:quickEditUserId
          }
          saveQuickEditDetails(tempPayload);
            setShowQuickEditModal(false);
        } else {
          message.error('Please correct the errors in the form.');
        }
    }

    const handleEditClick = (value) => {
        console.log(value,'value')
        setQuickEditUserId(value);
        let tempPayload = {
            userId:value
        }
        getUserDetails(tempPayload)
        //call get user details api
    };
    
    const handleQuickEditFormChange = (e) => {
        const { name, value } = e.target;
        console.log(name,value,'value')
        setQuickEditUserDetails({
        ...quickEditUserDetails,
        [name]: value,
        });

        if (name === 'email') {
        setQuickEditUserFormErrors({
            ...quickEditUserFormErrors,
            email: '', // Clear email error on change
        });
        }
    };

    const getUserDetails=(payload)=>{
        ServiceUtils.postRequest('kolEdit', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    console.log(response);
                    setQuickEditUserDetails(response?.data.data);
                    setShowQuickEditModal(true);
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching data.')
            }
        );
    }

    const saveQuickEditDetails=(payload)=>{
        ServiceUtils.postRequest('kolEdit', payload).then(
            (response) => {
                if (response.status === 200 && response?.data.status) {
                    console.log(response);
                    fetchAddInputsList();
                }
                else if(response.status === 401 ){
                    navigate('/logout');
                }
            },
            (error) => {
                alertService.error('Error while fetching data.')
            }
        );
    }

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;

        return (
            <Tag
            style={{
                display: 'flex',
                alignItems: 'center',
                background: '#f5f5f5',
                borderRadius: '2px',
                padding: '2px 5px',
                marginRight: '4px',
            }}
            onClose={onClose}
        >
           <span style={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                    <span
                        onMouseDown={(event) => event.stopPropagation()} 
                        onClick={(event) => {
                            event.stopPropagation(); 
                            handleEditClick(value);
                        }}
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                    >
                        <Tooltip title={"Edit"}>
                        <EditOutlined />
                        </Tooltip>
                    </span>
                </span>
            <CloseOutlined 
                onClick={onClose}
                style={{ marginLeft: 5, cursor: 'pointer' }} 
            />
        </Tag>
        );
    }


    return (
        <div className="eng-root" data-test="eng-container">
            <Card style={{ width: '100%', height: 'fit-content' }} bodyStyle={{ padding: '10px' }}>
                <BreadcrumbList active={[{ label: 'Meeting List ', link: '/home/meetingDetails/' + id }, { label: ' ', link: '' }, { label: ' Meeting Details', link: '' }]} />
            </Card>
            <div className="list-container">
                    {isEditEnabled &&
                        <>
                            {loader ?
                                <>
                                    <Row
                                        style={{alignItems: 'center',
                                            display: 'grid',
                                            height: 'calc(100vh - 80px)',padding: "0px"}}
                                    >
                                        <Col className="center-all" xs={{ flex: "100%" }}
                                            sm={{ flex: "100%" }}
                                            md={{ flex: "100%" }}
                                            lg={{ flex: "100%" }}
                                            xl={{ flex: "100%" }}>
                                            <Empty
                                            description={
                                                <Typography.Text>
                                                Loading...
                                                </Typography.Text>
                                                }
                                                /> 
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <div style={contentStyle}>
                                        <div style={{ padding: '5px' }}>
                                            <div className="first-step">
                                                <Row style={{ padding: '5px' }}>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Meeting Name</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?

                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                : <Input
                                                                    value={inputs?.meeting_name}
                                                                    name="meeting_name"
                                                                    style={{ width: '90%' }}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Meeting Description"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Meeting Venue</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?

                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                : <Input
                                                                    value={inputs?.meeting_venue}
                                                                    name="meeting_venue"
                                                                    style={{ width: '90%' }}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Meeting Venue"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    {/* <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Meeting Host</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?

                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                : <Input
                                                                    value={inputs?.meeting_host}
                                                                    name="meeting_host"
                                                                    style={{ width: '90%' }}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Meeting Host"
                                                                />
                                                            }
                                                        </div>
                                                    </Col> */}
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Start Time</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>

                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <DatePicker
                                                                    defaultValue={inputs?.start_date && dayjs(inputs?.start_date)}
                                                                    value={inputs?.start_date && dayjs(inputs?.start_date)}
                                                                    // defaultValue={inputs?.start_date}
                                                                    name="start_date"
                                                                    style={{ width: '90%' }}
                                                                    showTime
                                                                    format="YYYY-MM-DD HH:mm"
                                                                    placeholder="Select Start Date & Time"
                                                                    onChange={(date, dateString) => { handleCalender(date, dateString, "start_date") }}
                                                                // onOk={onOk}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">End Time</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <DatePicker
                                                                    defaultValue={inputs?.end_date && dayjs(inputs?.end_date)}
                                                                    value={inputs?.end_date && dayjs(inputs?.end_date)}
                                                                    name="end_date"
                                                                    style={{ width: '90%' }}
                                                                    showTime
                                                                    format="YYYY-MM-DD HH:mm"
                                                                    placeholder="Select End Date & Time"
                                                                    // onChange={onChange}
                                                                    onChange={(date, dateString) => { handleCalender(date, dateString, "end_date") }}
                                                                // onOk={onOk}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Conference</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    name="conference_id"
                                                                    defaultValue={inputs?.conference_id}
                                                                    value={inputs?.conference_id}
                                                                    // mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "conference_id") }}
                                                                    options={addInputFieldData?.conference}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Meeting Type</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    name="meeting_type"
                                                                    defaultValue={inputs?.meeting_type}
                                                                    value={inputs?.meeting_type}
                                                                    // mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "meeting_type") }}
                                                                    options={addInputFieldData?.meeting_type}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Meeting Category</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    name="meeting_type"
                                                                    defaultValue={inputs?.meeting_category}
                                                                    value={inputs?.meeting_category}
                                                                    // mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textoverflow: "ellipsis"
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "meeting_category") }}
                                                                    options={addInputFieldData?.meeting_category}
                                                                    // filterOption={filter}
                                                                    maxTagCount={"responsive"}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    {/* <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Attendee List</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    name="attendee_list"
                                                                    defaultValue={inputs?.attendee_list}
                                                                    value={inputs?.attendee_list}
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "attendee_list") }}
                                                                    options={addInputFieldData?.attendee}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col> */}
                                                    {/* <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">KOL Profile List</label>
                                                                <span className="text-mandatory">*</span>
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    defaultValue={inputs?.kol_profiles}
                                                                    value={inputs?.kol_profiles}
                                                                    name="kol_profiles"
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "kol_profiles") }}
                                                                    options={addInputFieldData?.kol_profile}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col> */}
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Commercial Objectives</label>
                                                                {/* <span className="text-mandatory">*</span> */}
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    defaultValue={inputs?.commercial_objectives}
                                                                    value={inputs?.commercial_objectives}
                                                                    name="commercial_objectives"
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "commercial_objectives") }}
                                                                    options={addInputFieldData?.commercial_objectives}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Medical Objectives</label>
                                                                {/* <span className="text-mandatory">*</span> */}
                                                            </div>
                                                            {!isEditEnabled ?
                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                :
                                                                <Select
                                                                    defaultValue={inputs?.medical_objectives}
                                                                    value={inputs?.medical_objectives}
                                                                    mode="multiple"
                                                                    allowClear
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    name="medical_objectives"
                                                                    showSearch
                                                                    placeholder="Please select"
                                                                    onChange={(value, label) => { handleSelectOnChange(value, label, "medical_objectives") }}
                                                                    options={addInputFieldData?.medical_objectives}
                                                                    // filterOption={filter}
                                                                    maxTagCount="responsive"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Attachments</label>
                                                                {/* <span className="text-mandatory">*</span> */}
                                                            </div>
                                                            {!isEditEnabled ?

                                                                <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                : 
                                                                <Upload
                                                                    style={{width: '90%'}}
                                                                    name="meeting_attachments"
                                                                    className="upload-man"
                                                                    onChange={(value)=>{multiAttchOnChange(value)}}
                                                                    beforeUpload={beforeUpload}
                                                                    multiple
                                                                    // action={""}
                                                                    customRequest={dummyRequest}
                                                                    defaultFileList={inputs?.meeting_attachments}
                                                                    // accept="file"
                                                                >
                                                                    <Button style={{width: '90%'}} icon={<UploadOutlined />}>Click to upload</Button>
                                                                </Upload>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                        sm={{ flex: '100%' }}
                                                        md={{ flex: '50%' }}
                                                        lg={{ flex: '25%' }}
                                                        xl={{ flex: '25%' }}>
                                                        <div style={{ display: 'grid' }} >
                                                            <div style={{ display: 'flex' }}>
                                                                <label class="form-label text-ellipsis-one-line">Send meeting invite to</label>
                                                            </div>
                                                            <div style={{marginTop:'5px'}}>
                                                                {!isEditEnabled ?
                                                                        <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                        :   
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <Checkbox 
                                                                            value={inputs?.arcutis_attendees_mail}
                                                                            name="arcutis_attendees_mail"
                                                                            // style={{ width: '90%' }}
                                                                            onChange={handleCheckboxChange}
                                                                            >Arcutis Attendees</Checkbox>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Checkbox 
                                                                            value={inputs?.kol_attendees_mail}
                                                                            name="kol_attendees_mail"
                                                                            // style={{ width: '90%' }}
                                                                            onChange={handleCheckboxChange}
                                                                            >KOL Attendees</Checkbox>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ padding: '5px' }}>
                                                    <Col className='form-group ' xs={{ flex: '100%' }}
                                                            sm={{ flex: '100%' }}
                                                            md={{ flex: '50%' }}
                                                            lg={{ flex: '50%' }}
                                                            xl={{ flex: '50%' }}>
                                                            <div style={{ display: 'grid' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <label class="form-label text-ellipsis-one-line">Note</label>
                                                                    {/* <span className="text-mandatory">*</span> */}
                                                                </div>
                                                                {!isEditEnabled ?

                                                                    <label class="text-only text-ellipsis-one-line">Some Value</label>
                                                                    : <Input.TextArea
                                                                        autoSize
                                                                        value={inputs?.meeting_notes}
                                                                        name="meeting_notes"
                                                                        rows={1}
                                                                        style={{ width: '100%' }}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Notes"
                                                                    />
                                                                }
                                                            </div>
                                                    </Col>
                                                </Row>
                                                {/* <div className="submit-block" style={{ padding: '15px' }}>
                                                    <div style={{ paddingRight: '10px' }}>
                                                        <Button style={{ background: '#E0B41C' }} type="primary" onClick={onSave}>Save</Button>
                                                    </div>
                                                    <Button style={{ color: '#E0B41C' }} onClick={cancelAddEdit} >Cancel</Button>
                                                </div> */}
                                            </div>
                                            <div className="first-step">
                                                <Table
                                                    rowClassName={() => 'editable-row'}
                                                    bordered
                                                    dataSource={dataSource}
                                                    columns={columns}
                                                    pagination={false}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ marginTop: 24 }}>
                                        <div className="submit-block">
                                            <div style={{ paddingRight: '10px' }}>
                                                <Button className="edit-meet-button" type="primary" onClick={() => SaveData()}>
                                                    Save
                                                </Button>
                                            </div>
                                            <Button onClick={cancelAddEdit} >Cancel</Button>
                                        </div>
                                    </div>
                                </>
                                
                            }
                        </>
                    }
                    <>
                            {!isEditEnabled &&
                                <>
                                    {/* <div className='home-tabs '>
                                        {tabList && tabList.map((tabs) =>
                                        <div className={classNames('tabs-list', { 'active': selectedMenu?.key === tabs.key })} onClick={() => onTabSelect(tabs)}>
                                            <Text>{tabs.label}</Text>
                                        </div>
                                        )}
                                    </div> */}
                                    {/* {selectedMeeting && selectedMeeting.meetingTitle &&
                                        <Title level={3} className='text-center' style={{ margin: '0px' }}>{selectedMeeting['meetingTitle']}</Title>
                                    }
                                    {selectedMeeting && selectedMeeting.meeting_host &&
                                        <Title level={4} className='text-center' style={{ margin: '0px' }}>Organizer : {selectedMeeting['meeting_host']}</Title>
                                    } */}
 
                                    {/* <div className='home-tabs '> */}
                                    {/* <Tabs defaultActiveKey="userManagement">
                                        {tabList && tabList.map((tabs) =>
                                            // <div className={classNames('tabs-list', { 'active': selectedMenu?.key === tabs.key })} onClick={() => onTabSelect(tabs)}>
                                            <TabPane tab="User Management" key="userManagement">
                                                <Text>{tabs.label}</Text>
                                            </TabPane>
                                        )}
                                        </Tabs> */}
                                    {/* </div> */}
                                    <Tabs
                                        style={{ marginTop: "10px" }}
                                        defaultActiveKey="1"
                                        items={tabList}
                                        onChange={onTabSelect}
                                        />
                                    {meetingDetails &&
                                        <div className="meeting-header">
                                            <Card style={{ borderRadius: '8px' }} styles={{body:{padding:'5px'}}}>
                                                <Row align="start" gutter={16}>
                                                    <Col xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '10%' }} lg={{ flex: '10%' }} xl={{ flex: '10%' }}>
                                                    <Card styles={{body:{height: '100%',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column'}}} 
                                                    style={{ backgroundColor: '#FFF4AF', borderRadius: '8px', textAlign: 'center' ,height: '100%'}}
                                                    >
                                                        <div style={{ fontSize: '18px', fontWeight: 'bold' }}> {meetingDetails?.meeting_start}</div>
                                                        <div style={{ fontSize: '18px', fontWeight: 'bold' }}> { meetingDetails?.meeting_end &&
                                                        <>
                                                            <div style={{display:'flex', justifyContent:'center'}}>to</div>
                                                            <>{meetingDetails?.meeting_end}</>
                                                        </>

                                                    }</div>
                                                    </Card>
                                                    </Col>
                                                    {/* <Col xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '45%' }} lg={{ flex: '45%' }} xl={{ flex: '45%' }}>
                                                    <div style={{ fontSize: '18px', fontWeight: 'bold', margin: '10px 0' }}>
                                                        <Tooltip title={meetingDetails?.meeting_name}>
                                                        <CalendarOutlined style={{ marginRight: '8px' }} /> Meeting :{meetingDetails?.meeting_name}
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ marginBottom: '10px' }}>
                                                    <Tooltip title={meetingDetails?.meeting_timings}>
                                                        <ClockCircleOutlined style={{ marginRight: '8px' }} /> Timings :{meetingDetails?.meeting_timings}
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip title={meetingDetails?.meeting_venue}>
                                                        <EnvironmentOutlined style={{ marginRight: '8px' }} /> Location :{meetingDetails?.meeting_venue}
                                                        </Tooltip>
                                                    </div>
                                                    </Col > */}
                                                    <Col style={{margin:'15px 10px'}} xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '35%' }} lg={{ flex: '35%' }} xl={{ flex: '35%' }}>
                                                        <Row align="middle" gutter={[16, 16]}>
                                                        <Col>
                                                            <CalendarOutlined style={{ fontSize: '24px', color: '#b0b0b0' }} />
                                                        </Col>
                                                        <Col>
                                                            <Tooltip title={meetingDetails?.meeting_name}>
                                                            <Row>
                                                                <Text type="secondary">Meeting : </Text>
                                                                &nbsp;<div style={{fontWeight:'bold',
                                                                         maxWidth: '25rem',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'}}>{meetingDetails?.meeting_name}</div>
                                                            </Row>
                                                            </Tooltip>
                                                        </Col>
                                                        </Row>
                                                        <Row align="middle" gutter={[16, 16]} style={{ marginTop: '16px' }}>
                                                        <Col>
                                                            <ClockCircleOutlined style={{ fontSize: '24px', color: '#b0b0b0' }} />
                                                        </Col>
                                                        <Col>
                                                            <Tooltip title={meetingDetails?.meeting_timings}>
                                                            <Row>
                                                                <Text type="secondary">Timings : </Text>
                                                                &nbsp;<div style={{fontWeight:'bold'}}>{meetingDetails?.meeting_timings}</div>
                                                            </Row>
                                                            </Tooltip>
                                                        </Col>
                                                        </Row>
                                                        <Row align="middle" gutter={[16, 16]} style={{ marginTop: '16px' }}>
                                                        <Col>
                                                            <EnvironmentOutlined style={{ fontSize: '24px', color: '#b0b0b0' }} />
                                                        </Col>
                                                        <Col>
                                                            <Tooltip title={meetingDetails?.meeting_venue}>
                                                            <Row>
                                                                <Text type="secondary">Location : </Text>
                                                                &nbsp;<div style={{fontWeight:'bold',
                                                                        maxWidth: '25rem',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'}}>{meetingDetails?.meeting_venue}</div>
                                                            </Row>
                                                            </Tooltip>
                                                        </Col>
                                                        </Row>
                                                    </Col>
                                                   <Col style={{display:'flex',justifyContent:"flex-end",margin:'10px 0'}} xs={{ flex: '50%' }} sm={{ flex: '50%' }} md={{ flex: '30%' }} lg={{ flex: '30%' }} xl={{ flex: '30%' }}>
                                                   {userDetails?.role_access?.home?.conferences?.edit && <Tooltip title="Create a Group chat">
                                                    <Button icon={<GroupOutlined />} style={{ marginRight: '10px' }}  onClick={openGroupChatSer}>Create Group Chat</Button>
                                                    </Tooltip>}
                                                    {userDetails?.role_access?.home?.conferences?.edit && <Tooltip title="Edit meeting">
                                                    <Button icon={<EditOutlined />}  onClick={editMeeting}>Edit</Button>
                                                    </Tooltip>}
                                                    </Col>
                                                    <Col style={{display: 'flex',alignItems: 'center'}} xs={{ flex: '30%' }} sm={{ flex: '30%' }} md={{ flex: '5%' }} lg={{ flex: '5%' }} xl={{ flex: '5%' }}>
                                                    <Avatar size={100} icon={<UserOutlined />} src={meetingDetails?.host_image?.length && meetingDetails?.host_image} />
                                                    </Col>
                                                    <Col style={{display: 'flex',alignItems: 'center'}} xs={{ flex: '50%' }} sm={{ flex: '50%' }} md={{ flex: '10%' }} lg={{ flex: '10%' }} xl={{ flex: '10%' }}>
                                                    <div >
                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{meetingDetails?.meeting_host}</div>
                                                        <div className="end-align" style={{fontWeight: 500}}>
                                                            Organiser
                                                        </div>
                                                    </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    }
                                    <div style={{overflowX:'hidden'}}>
                                    {selectedMenu === 'objectives'  &&
                                        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                                            {objectiveLists && objectiveLists?.length > 0 && objectiveLists.map((el,index) =>
                                                <Col className='tile' xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '100%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                                    {/* <Card className="custom-box-shadow" style={{ backgroundColor: "#f6f6f6", padding:'0px' }}> */}
                                                        <div style={{ height: '18rem', width: '99%', overflow: 'hidden' }}>
                                                            <h3 level={5} style={{ marginBottom: '8px',padding:'7px',paddingLeft:0 }}>
                                                               <span>{el.label}</span>
                                                            </h3>
                                                            <div style={{ height: 'calc(100vh - 589px)' }}>
                                                                {el && el.data.map((data,itemIndex) =>
                                                                    // <div style={{ backgroundColor: index > 0 ? '#e8e4f4' : '#e8ecf4', marginBottom:'5px', padding:'5px 12px'}}>
                                                                    //     {/* <ArrowRightOutlined /> */}
                                                                    //     <span style={{ marginLeft: '5px', fontWeight: '400' }}>Topic {itemIndex + 1}</span>
                                                                    //     <h4 level={5} className='' style={{ marginLeft: '5px', fontWeight: '500' }}>{data.label}</h4>
                                                                    // </div>
                                                                    <Card title={`Agenda ${itemIndex + 1}`} styles={{
                                                                        header:{padding: '5px 10px',
                                                                        minHeight: 'auto',
                                                                        backgroundColor:'#F5E6E6'},
                                                                        body:{padding:'10px',
                                                                        fontWeight:500
                                                                        }
                                                                    }} 
                                                                        style={{ marginBottom: '10px' }}>
                                                                       {data.label}
                                                                    </Card>
                                                                )}
                                                            </div>
                                                        </div>
                                                    {/* </Card> */}
                                                </Col>
                                            )}
                                        </Row>
                                    }
                                    {selectedMenu === 'attendee' &&
                                        <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
                                            <Col className='tile' xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '100%' }} lg={{ flex: '100%' }} xl={{ flex: '100%' }}>
                                            {isMobile?
                                                <>
                                                <div style={{ padding: '20px' ,marginBottom:"20px"}}>
                                                <Row gutter={[16, 16]}>
                                                    {attendeeList?.tableData?.bodyContent.map((item) => (
                                                    <Col xs={24} sm={12} md={8} key={item?.slNo}>
                                                        <Card title={item.name} styles={{body:{padding:"15px"}}} bordered={false}>
                                                        <p><Text type="secondary">Email : </Text>&nbsp;<span style={{fontWeight:'bold'}}>{item?.email}</span></p>
                                                        <p><Text type="secondary">Attendee Type :</Text>&nbsp;<span style={{fontWeight:'bold'}}>{item?.type}</span></p>
                                                        <p><Text type="secondary">Invitation Type : </Text>&nbsp; <span><Tag color={item.backgroundColor} style={{padding:'3px 16px',minWidth: '5rem'}} key={item}>
                                                            <span style={{ color: 'black', fontWeight: '500' }}>{item.invitation_type}</span>
                                                        </Tag></span></p>
                                                        {item?.chat?.length ?
                                                            <a style={{display:'block',textAlign:"end",fontSize:"20px"}} onClick={() => {window.open(item?.chat)}}>
                                                                <WechatWorkOutlined />
                                                                {"   "}
                                                                Chat
                                                                {" "}
                                                            </a>
                                                        :
                                                            <> </>
                                                        }
                                                        </Card>
                                                    </Col>
                                                    ))}
                                                </Row>
                                                </div>
                                                </>
                                                : 
                                                <div style={{ marginBottom: '10px', overflow: 'auto'}}>
                                                    <Table columns={attendeeList.tableData.headerContent} scroll={{ x: true, y: 'calc(100vh - 360px)' }} dataSource={attendeeList.tableData.bodyContent} pagination={false} />
                                                </div>
                                                }

                                                {/**old code */}
                                                {/* <div className='profile-body'>
                                        
                                                {/* <Card className='pointer' style={{ width: '100%', height: 'fit-content', boxShadow: '1px 1px 10px 1px #cccccc', borderRadius: '4px' }}> */}
                                                {/* <Space size={16}>
                                                
                                                    <Space wrap size={16}> */}
                                                {/* <div className="just">
                                                        <div>
                                                    <Title level={5}>KOL</Title>
                                                        <Title className="pointer" style={{ marginTop: '5px'}}level={4} onClick={() => onProfileSelect('/home/profileDetails/104')}>April Armstrong</Title>
                                                        <Title level={5}>april.armstrong@med.usc.edu</Title>
                                                    </div>
                                                    <div>
                                                    <Title level={5}>Attendee</Title>
                                                        <Title className="pointer" style={{ marginTop: '5px'}} level={4} onClick={() => onProfileSelect('/home/attendeeDetails/3')}>Jamie Lewis</Title>
                                                        <Title level={5}>jlewis@arcutis.com</Title>
                                                    </div>
                                                </div> */}

                                                {/*                                     
                                                    </Space>
                                                
                                                </Space> */}
                                                {/* </Card> */}
                                                {/*</div> */}

                                            </Col>
                                            {/* <Col className='tile' xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '100%' }} lg={{ flex: '100%' }} xl={{ flex: '50%' }}>
                                            <Card className="custom-box-shadow">
                                                <div style={{ height: '13.3rem', overflowY: 'auto', overflow: 'hidden' }}>
                                                    <Title level={4} className='text-center' style={{ marginBottom: '20px' }}>Earlier Engagement and Notes</Title>
                                                    <Timeline
                                                        mode={"left"}
                                                        items={attendeeList.engagementAndNotes}
                                                    />
                                                </div>
                                            </Card>
                                        </Col> */}
                                        </Row>
                                    }
                                    {selectedMenu === 'followUp' &&
                                        // <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
                                        //     <Col className='tile' xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '100%' }} lg={{ flex: '100%' }} xl={{ flex: '50%' }}>
                                        //         {/* <div style={{ marginTop: '15px' }}>
                                        //     <Title level={4} className='text-center' style={{ marginBottom: '20px' }}>Earlier Engagement and Notes</Title>
                                        //     <Timeline
                                        //         mode={"left"}
                                        //         items={[
                                        //             {
                                        //                 label: '2024-02-20',
                                        //                 children: 'Meeting at SFO-Notes',
                                        //             },
                                        //             {
                                        //                 label: '2024-02-18',
                                        //                 children: 'Meeting at LA Notes',
                                        //             },
                                        //             {
                                        //                 label: '2024-02-15',
                                        //                 children: 'Meeting at Arcutis office Notes',
                                        //             },
                                        //             {
                                        //                 label: '2024-02-10',
                                        //                 children: 'Meeting at California Notes',
                                        //             },
                                        //         ]}
                                        //     />
                                        // </div> */}

                                        //         <div className="eng-data">
                                        //             <Card className="custom-box-shadow">
                                        //                 <Row gutter={[16, 5]}>
                                        //                     <Col xs={{ flex: '50%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                         <div style={{ width: '100%' }}>
                                        //                             <div className='eng-form-root'>
                                        //                                 Follow-up Requested
                                        //                             </div>
                                        //                             <Radio.Group onChange={(e) => { onChange(e); handleDropdownChange(e.target.value, 'followUpRequested'); }} value={value}>
                                        //                                 <Radio value={1}>Yes</Radio>
                                        //                                 <Radio value={2}>No</Radio>
                                        //                             </Radio.Group>
                                        //                         </div>
                                        //                     </Col>
                                        //                     <Col xs={{ flex: '50%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                         <div style={{ float: 'right' }}>
                                        //                             <Button className="edit-meet-button" type="primary" onClick={handleSubmit}>Submit</Button>
                                        //                         </div>
                                        //                     </Col>
                                        //                     {showActionSelection &&
                                        //                         <>
                                        //                             <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                                 <div style={{ width: '100%', marginTop: '15px' }} >
                                        //                                     <div className='eng-form-root'>
                                        //                                         Action On
                                        //                                     </div>
                                        //                                     <Select defaultValue="Select an Action On" value={formData.action_on || undefined} style={{ width: '100%' }}  maxTagCount="responsive" onChange={(value,label) => handleDropdownChange(value, 'action_on',label)}>
                                        //                                         {/* {formData.actionOn === '' && <Option value="">Select an Action On</Option>} */}
                                        //                                         {actionDetails && actionDetails.action_on.length > 0 && actionDetails.action_on.map(el =>
                                        //                                             <Option value={el.value}>{el.label}</Option>
                                        //                                         )}
                                        //                                     </Select>
                                        //                                 </div>
                                        //                             </Col>
                                        //                             <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                                 <div style={{ width: '100%', marginTop: '15px' }}>
                                        //                                     <div className='eng-form-root'>
                                        //                                         Action Assigned To
                                        //                                     </div>
                                        //                                     <Select defaultValue="Select an Action" value={formData.action_assigned_to || undefined} style={{ width: '100%' }} onChange={(value) => handleDropdownChange(value, 'action_assigned_to')}>
                                        //                                         {actionDetails && actionDetails.action_assigned_to.length > 0 && actionDetails.action_assigned_to.map(el =>
                                        //                                             <Option value={el.value}>{el.label}</Option>
                                        //                                         )}
                                        //                                     </Select>
                                        //                                 </div>
                                        //                             </Col>
                                        //                             {/* <Col xs={{ flex: '33%' }} sm={{ flex: '33%' }} md={{ flex: '33%' }} lg={{ flex: '33%' }} xl={{ flex: '33%' }}>
                                        //                             <Button style={{ background: '#E0B41C', marginTop:'3rem' }} type="primary" onClick={handleSubmit}>Add</Button>
                                        //                         </Col> */}
                                        //                             <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                                 <div style={{ width: '100%', marginTop: '15px' }}>
                                        //                                     <div className='eng-form-root'>
                                        //                                         Status of Follow-up
                                        //                                     </div>
                                        //                                     <Select defaultValue="Select an Follow-up status" value={formData.action_status || undefined} style={{ width: '100%' }} onChange={(value) => handleDropdownChange(value, 'action_status')}>
                                        //                                         {actionDetails && actionDetails.action_status.length > 0 && actionDetails.action_status.map(el =>
                                        //                                             <Option value={el.value}>{el.label}</Option>
                                        //                                         )}
                                        //                                     </Select>
                                        //                                 </div>
                                        //                             </Col>
                                        //                             <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '50%' }} lg={{ flex: '50%' }} xl={{ flex: '50%' }}>
                                        //                                 <div style={{ width: '100%', marginTop: '15px' }}>
                                        //                                     <div className='eng-form-root'>
                                        //                                         Mail Type 
                                        //                                     </div>
                                        //                                     <Select defaultValue="Select an Mail Type" value={formData.composeMail || undefined} style={{ width: '100%' }} onChange={(value) => handleDropdownChange(value, 'composeMail')}>
                                        //                                         {actionDetails && actionDetails.mail_type?.length > 0 && actionDetails?.mail_type.map(el =>
                                        //                                             <Option value={el.value}>{el.label}</Option>
                                        //                                         )}
                                        //                                     </Select>
                                        //                                 </div>
                                        //                             </Col>
                                        //                         </>
                                        //                     }

                                        //                 </Row>

                                        //             </Card>
                                        //         </div>
                                        //     </Col>
                                        //     <Col className='tile' xs={{ flex: '100%' }} sm={{ flex: '100%' }} md={{ flex: '100%' }} lg={{ flex: '100%' }} xl={{ flex: '50%' }}>
                                        //         <div style={{ width: '100%', height: '100%', marginRight: '-14px' }}>
                                        //             <Table style={{ width: '100%', height: '100%' }} columns={followUpList.headerContent} dataSource={followUpList.bodyContent} bordered pagination={false} scroll={{ y: 228, }} />
                                        //         </div>

                                        //     </Col>
                                        // </Row>
                                        <FollowUp actionDetails={actionDetails} meetingID={meetingID} userDetails={userDetails}/>

                                    }
                                    </div>
                                   
                                </>
                            }
                    </>
            </div>
            {drawerState==="composeMail" && <DrawerComponent passData="composeMail" meetingID={meetingID} defaultSubject={currentAction ? selectedMeeting['meetingTitle'] + " - " + currentAction : selectedMeeting['meetingTitle']} closeDrawer={(e)=>{setDrawerState("")}} mailInputs={mailComposeInputs} setMailInputs={(e)=>{SetMailComposeInputs(e)}} setValidatedMailFlag={(e)=>{checkMailFlag(e)}}/>}
            {drawerState==="traceMail" && <DrawerComponent passData="traceMail" meetingID={meetingID} followUpID={formData?.id} closeDrawer={(e)=>{setDrawerState("")}} />}
            <Modal
      title="Edit User Details"
      visible={showQuickEditModal}
      onOk={saveQuickEditUserDetails}
      okText={'Save'}
      onCancel={()=>{
        setQuickEditUserFormErrors({})
        setShowQuickEditModal(false)}
    }
    >
      <div style={{ marginBottom: 16 }}>
        <label>First Name</label>
        <Input
          name="first_name"
          value={quickEditUserDetails?.first_name}
          onChange={handleQuickEditFormChange}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <label>Last Name</label>
        <Input
          name="last_name"
          value={quickEditUserDetails?.last_name}
          onChange={handleQuickEditFormChange}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <label>Email <span style={{ color: 'red' }}>*</span></label>
        <Input
          name="email"
          value={quickEditUserDetails?.email}
          onChange={handleQuickEditFormChange}
        />
        {quickEditUserFormErrors?.email && <div style={{ color: 'red' }}>{quickEditUserFormErrors?.email}</div>}
      </div>
    </Modal>
        </div>
    )
}